export default function AboutUs() {
  return (
    <div className="flex lg:flex-row flex-col items-center justify-center mx-auto gap-6">
      <div className="text-center flex justify-center items-center flex-1 w-6/12 lg:w-8/12 xl:w-full">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/aboutus.png`}
          alt="aboutus"
        />
      </div>
      <div className="text-[#E3E3E3] leading-loose tracking-wide sm:text-xl sm:mx-12 xl:ml-0 xl:mr-28 text-left flex-1">
        <p className="mb-6">
          <span className="text-pantone text-3xl">At Playzumi</span>, we are a
          dynamic slot game studio specializing in the creation of innovative,
          custom-built, and exceptionally creative online casino games. Based in
          Curacao, our diverse team hails from all corners of the globe, united
          by a shared passion for game development.
        </p>
        <p className="mb-6">
          <span className="text-pantone text-3xl">Playzumi</span> is rapidly
          making waves in the online gaming industry, with our games gaining
          recognition for their stunning graphics, high-quality production, and
          immersive features. Every aspect of our games from concept and
          gameplay to math models, art, design, animations, themes, music, sound
          effects, and development is crafted in-house, ensuring flawless
          execution every time.
        </p>
        <p className="mb-6">
          Our mission is simple: to create original, cutting-edge casino games
          that will captivate players and endure the test of time.
        </p>
      </div>
    </div>
  );
}
