import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "./carousel.scss";
import { defaultSlidePerBreakpoints } from "../../common/utils";

export default function Carousel(props) {
  const {
    data = {},
    showOverLay = true,
    imgPaths = null,
    slidePerBreakpoints = defaultSlidePerBreakpoints,
  } = props;

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  if (!data?.games || imgPaths?.length === 0) {
    return null;
  }

  return (
    <div className="my-carousel">
      <div className="w-[85%] mx-auto  relative">
        <div ref={prevRef} className="swiper-button-prev-1"></div>
        <div ref={nextRef} className="swiper-button-next-1"></div>
        <Swiper
          spaceBetween={20}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          breakpoints={slidePerBreakpoints}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
            swiper?.slideTo(1);
            setTimeout(() => {
              swiper?.slideTo(0);
            }, 300);
          }}
        >
          {imgPaths?.map((img, i) => (
            <SwiperSlide key={i} className="rounded-box swipper-slide">
              <img alt="img-game" src={img} className="object-fill" />
            </SwiperSlide>
          ))}
          {!imgPaths &&
            data?.games?.map((item) => (
              <SwiperSlide
                key={item.id}
                className="hover:z-10 rounded-box swipper-slide cursor-pointer"
              >
                <img
                  alt="img-game"
                  src={`${process.env.PUBLIC_URL}/${data.path}/${item.code}/icon.png`}
                  className="rounded-box  object-fill"
                />
                {showOverLay && (
                  <div className="w-full h-full text-center absolute flex items-end justify-center group">
                    <div className="w-full h-full text-center absolute group-hover:opacity-60 transition rounded-box group-hover:bg-gray-600"></div>
                    <div className="text-center leading-loose tracking-wide z-10 text-pantone opacity-0  group-hover:opacity-100 ">
                      {/* <div className=" text-2xl">{item?.name}</div> */}
                      <div className="mb-2">
                        <Link
                          to={`/game/${item.code}`}
                          className="btn text-text bg-[#004B55] hover:bg-[#13353f] rounded hover:border-pantone border-pantone-light border-2"
                        >
                          GAME DETAILS
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}
