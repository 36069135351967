import { useEffect, useState } from "react";
import Carousel from "../component/carousel/carousel";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import { useParams } from "react-router-dom";
import GameDetail from "../component/game-detail";
import { GameBigSlidePerBreakpoints } from "../common/utils";

export default function GamePage() {
  const [game, setGame] = useState();
  const [data, setData] = useState({});
  const params = useParams();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/game.json`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const game = data?.games?.find((g) => g?.code === params?.id);
    setGame(game);
  }, [params.id, data]);

  const sliderImgPaths = game?.screens?.map((item) => {
    return `${process.env.PUBLIC_URL}/${data?.path}/${game?.code}/${item}.jpg`;
  });

  return (
    <>
      <div className="min-h-full bg-b">
        <Navbar />
        <main className="container mx-auto">
          <section className="rounded-box mb-8 relative scroll-m-20" id="home">
            <div className="hero-bg absolute box-shadow top-0 left-0 right-0 rounded-box h-[90%]"></div>
            <img
              src={`${process.env.PUBLIC_URL}/${data?.path}/${game?.code}/icon.png`}
              alt="img-game"
              className="text-center relative z-10 mx-auto"
              width={300}
            />
          </section>

          <section
            className="bg-even rounded-box my-10 pb-10 px-4 scroll-m-20 box-shadow"
            id="aboutus"
          >
            <h2 className="text-3xl text-pantone  text-center py-8">
              GAME <span className=" font-bold"> US</span>
            </h2>
            <article className="text-[#E3E3E3] leading-loose tracking-wide max-w-6xl mx-auto pb-4 text-lg">
              {game?.description}
            </article>
            <GameDetail game={game} path={data?.path} />
          </section>
          {sliderImgPaths?.length > 0 ? (
            <section
              className="bg-odd rounded-box my-10 pb-4 px-4 scroll-m-20 box-shadow"
              id="ourgames"
            >
              <h2 className="text-3xl text-pantone text-center pt-8">
                {game?.name}
              </h2>
              <div>
                <Carousel
                  data={data}
                  imgPaths={sliderImgPaths}
                  slidePerBreakpoints={GameBigSlidePerBreakpoints}
                />
              </div>
            </section>
          ) : null}
          <section
            className="bg-even rounded-box my-10 pb-4 px-4 scroll-m-20 box-shadow"
            id="ourgames"
          >
            <h2 className="text-3xl text-pantone text-center pt-8">Games</h2>
            <div>
              <Carousel data={data} />
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}
